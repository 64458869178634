// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.loader {
  width: 4rem;
  height: 4rem;
  stroke: #fff;
  stroke-width: 12;
}

.loaderIcon {
  display: inline-block;
  width: 100%;
  height: 100%;
  animation: spin 1s infinite linear;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
