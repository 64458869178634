import React from 'react';
import store, { RequestStatus, RoomStatus } from '@src/store/index.js';
import Connect from './components/Connect/Connect.jsx';
import ConnectTest from './components/Connect/ConnectTest.jsx';
import Holding from './components/Holding/Holding.jsx';
import Scenario from './components/Scenario/Scenario.jsx';
import PKG from '/package.json';

import './App.css';

function renderPage(connection, roomStatus) {
  if (store.isTest) {
    return <ConnectTest />;
  }
  if (connection.status === RequestStatus.LOADED) {
    if (roomStatus === RoomStatus.SCENARIO) {
      return <Scenario />;
    }
    return <Holding />;
  }
  return <Connect />;
}

export default function App(props) {
  const connection = store.connection.use();
  const roomStatus = store.roomState.useSelector(roomState => roomState?.status);
  return (
    <div className="appContainer">
      {renderPage(connection, roomStatus)}
      {DEBUG && <div className="appBuildVersion">{PKG.version}</div>}
    </div>
  );
}
