import React, { useMemo } from 'react';
import store from '@src/store/index.js';
import * as Icon from '@src/components/Icon/Icon.jsx';

import './OrderingResult.css';

function isValidAnswer(answer, items) {
  if (!answer) return false;
  const count = items.length;
  if (answer.length !== count) return false;
  const copy = answer.slice().sort((a, b) => a - b);
  for (let i = 0; i < count; ++i) {
    if (copy[i] !== i) return false;
  }
  return true;
}

function computeZoneBounds(zones) {
  const bounds = [];
  let curBound = null;
  const n = zones.length;
  for (let i = 0; i < n; ++i) {
    if (zones[i] !== (curBound === null)) {
      if (curBound) {
        curBound.push(i / n);
        curBound = null;
      } else {
        curBound = [i / n];
        bounds.push(curBound);
      }
    }
  }
  if (curBound) curBound.push(1);
  return bounds;
}

function getWrongZones(ownAnswer, answer) {
  const zones = [];
  const numItems = answer.length;
  let prev = -1;
  let cur = -1;
  let next = ownAnswer[0];
  for (let i = 0; i < numItems; ++i) {
    prev = cur;
    cur = next;
    next = i + 1 < numItems ? ownAnswer[i + 1] : -1;
    const j = answer.indexOf(cur);
    const prev2 = j - 1 >= 0 ? answer[j - 1] : -1;
    const next2 = j + 1 < numItems ? answer[j + 1] : -1;
    zones.push(prev === prev2);
    zones.push(next === next2);
  }
  return computeZoneBounds(zones);
}

export default function OrderingResult(props) {
  const { topLabel, bottomLabel, items, order, answer } = props;
  const ownAnswer = store.getAnswer();
  const valid = useMemo(() => isValidAnswer(ownAnswer, items), [ownAnswer, items]);
  const wrongZones = useMemo(() => valid ? getWrongZones(ownAnswer, answer) : [], [valid, ownAnswer, answer]);
  let sectionName = 'Correct Order';
  if (topLabel && bottomLabel) {
    sectionName += ` (${topLabel} to ${bottomLabel})`;
  }
  sectionName += ':';
  return (
    <div className="orderingResultFrame">
      <div className="orderingResultSection">
        <h3 className="orderingResultSectionName">{sectionName}</h3>
        <div className="orderingResultFlex">
          <div className="orderingResultBar transparent" />
          <ol className="orderingResultList">
            {answer.map((index, i) => (
              <li key={'item-' + i}>{items[index]}</li>
            ))}
          </ol>
        </div>
      </div>
      {valid && (
        <div className="orderingResultSection">
          <h3 className="orderingResultSectionName">Your order:</h3>
          <div className="orderingResultFlex">
            <div className="orderingResultBar">
              {wrongZones.map(([start, end], i) => (
                <div
                  className="orderingResultBarWrong"
                  key={'bar-' + i}
                  style={{
                    top: `${start * 100}%`,
                    bottom: `${(1 - end) * 100}%`
                  }}
                />
              ))}
            </div>
            <ol className="orderingResultList">
              {ownAnswer.map((index, i) => (
                <li key={'item-' + i}>{items[index]}</li>
              ))}
            </ol>
          </div>
        </div>
      )}
    </div>
  );
}

// <li key={'item-' + i}>
//   <span>{items[index]}</span>
//   {ownAnswer[i] === answer[i] && (
//     <Icon.CheckInline className="matchingResultItemIcon" />
//   )}
// </li>
