// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.delegatesContainer {
  padding-top: 3rem;
}

.delegatesFrame {
  display: flex;
  height: 4rem;
}

.delegatesValue {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #071920;
  width: 4rem;
  position: relative;
}

.delegatesValueLabel {
  display: flex;
  justify-content: center;
  width: inherit;
  margin: 0;
  position: absolute;
  top: -0.35rem;
  color: #fff;
  font-size: 0.9rem;
  text-align: center;
  line-height: 1.25;
  transform: translateY(-100%);
}

.delegatesValueCount {
  margin: 0;
  color: #fff;
  font-size: 2rem;
  line-height: 1.25;
}

.delegatesProgress {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(7, 25, 32, 0.7);
  padding: 0 1rem;
  position: relative;
}

.delegatesProgressBar {
  background-color: #3b555d;
  height: 0.25rem;
  overflow: hidden;
}

.delegatesProgressBarActive {
  background-color: #fff;
  width: inherit;
  height: inherit;
  transition: transform 250ms;
}

.delegatesProgressIconGutter {
  position: absolute;
  top: 0;
  left: 2rem;
  right: 2rem;
  bottom: 0;
  transition: transform 250ms;
}

.delegatesProgressIcon {
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%);
  fill: #50c364;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
