import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import store, { RequestStatus } from '@src/store/index.js';
import { getAndRemoveQueryVariable } from '@src/utils/utils.js';
import Loader from '@src/components/Loader/Loader.jsx';
import natsLogoWhiteSvg from '@assets/nats-logo-white.svg';

import './Connect.css';

export default function Connect() {
  const [code, setCode] = useState(store.suggestedCode);
  const { status, error } = store.connection.use();
  function onChange(e) {
    setCode(e.currentTarget.value.toUpperCase());
  }
  function onSubmit(e) {
    e.preventDefault();
    store.connect(code);
  }
  // useEffect(() => {
  //   const queryCode = getAndRemoveQueryVariable('code');
  //   if (queryCode && /^[A-Z0-9]{4}$/.test(queryCode)) {
  //     setCode(queryCode);
  //     store.connect(queryCode);
  //   }
  // }, []);
  return (
    <div className="connectContainer">
      <div className="connectFrame">
        <div className="connectHeader">
          <img className="connectBrandLogo" alt="NATS Logo" src={natsLogoWhiteSvg} draggable="false" />
          <p className="connectTitle">Welcome to the<br /><strong>ATSEP Basic Scenarios</strong></p>
        </div>
        <div className="connectBody">
          <p className="connectCta">To begin, please enter the code your instructor gives you.</p>
          <form className="connectForm" onSubmit={onSubmit}>
            <input
              className="connectInput"
              type="text"
              name="code"
              value={code}
              placeholder="Enter code"
              onChange={onChange}
            />
            <div className="connectSubmit">
              {status === RequestStatus.INITIAL ? (
                <input type="submit" className="btn connectBtn" value="Submit" disabled={!code} />
              ) : (
                <Loader className="connectLoader" />
              )}
            </div>
          </form>
        </div>
        <div className={classNames('connectFooter', !error && 'hidden')}>
          <p className="connectMessage">{error}</p>
        </div>
      </div>
    </div>
  );
}
