// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.submitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5.5rem;
  margin: 0.5rem 0;
}

.submitLoader {
  width: 2.5rem;
  height: 2.5rem;
}

.submitMessage {
  width: 100%;
  margin: 0;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
}

.submitMessage strong {
  font-weight: bold;
}

.submitBtn {
  cursor: pointer;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
