import React from 'react';
import classNames from 'classnames';
import store, { RequestStatus } from '@src/store/index.js';
import Loader from '@src/components/Loader/Loader.jsx';

import './Submit.css';

export default function Submit(props) {
  const { status } = store.answer.use();
  return (
    <div className={classNames('submitContainer', props.className)}>
      {status === RequestStatus.LOADING ? (
        <Loader className="submitLoader" />
      ) : status === RequestStatus.LOADED ? (
        <p className="submitMessage">Your answer has been submitted.<br /><strong>Please wait for the instructor to reveal the result.</strong></p>
      ) : (
        <input
          type="submit"
          className="btn btnXLarge submitBtn"
          value={props.btnText ?? 'Submit'}
          disabled={props.disabled}
          onClick={props.onBtnClick}
        />
      )}
    </div>
  );
}
