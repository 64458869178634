import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import store, { RequestStatus } from '@src/store/index.js';
import { useInterval } from '@src/utils/hooks.js';
import { formatTime } from '@src/utils/utils.js';
import Loader from '@src/components/Loader/Loader.jsx';
import natsLogoWhiteSvg from '@assets/nats-logo-white.svg';

import '../Connect/Connect.css';

export default function ConnectTest(props) {
  const { status, error } = store.connection.use();
  const timerRef = useRef(null);
  useInterval(() => {
    const timer = timerRef.current;
    if (!timer) return;
    const t = store.getTestTime();
    let text = '';
    if (t > 0) {
      text = formatTime(t);
    } else if (t === 0) {
      text = 'test passed! ✅';
    }
    timer.textContent = text;
  }, 1000);
  return (
    <div className="connectContainer">
      <div className="connectFrame">
        <div className="connectHeader">
          <img className="connectBrandLogo" alt="NATS Logo" src={natsLogoWhiteSvg} draggable="false" />
          <p className="connectTitle">Welcome to the<br /><strong>ATSEP Basic Scenarios</strong></p>
        </div>
        <div className="connectBody">
          <p className="connectCta">This page lets you test the backend of the NATS Scenario Player. Please ensure you are accessing it from within NATS private network.</p>
          <div className="connectSubmit" style={{ margin: '1.5rem 0' }}>
            {status === RequestStatus.INITIAL ? (
              <button type="button" className="btn connectBtn" onClick={() => store.createTestRoom()}>Connect</button>
            ) : status === RequestStatus.LOADED ? (
              <button type="button" className="btn connectBtn" onClick={() => store.closeTestRoom()} style={{ backgroundColor: '#ffcf00' }}>Close</button>
            ) : (
              <Loader className="connectLoader" />
            )}
          </div>
          <p className="connectCta" ref={timerRef}></p>
        </div>
        <div className={classNames('connectFooter', !error && 'hidden')}>
          <p className="connectMessage">{error}</p>
        </div>
      </div>
    </div>
  );
}
