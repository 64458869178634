// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.headerContainer {
  height: 5rem;
}

.headerFrame {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background-color: #07191f;
  width: 100%;
  height: inherit;
  padding: 0 0.5rem;
  overflow: hidden;
  position: fixed;
  z-index: 10;
}

.headerLeft {
  flex: 1 0 0;
  display: flex;
  justify-content: flex-start;
}

.headerBrandLogo {
  height: 1.75rem;
  margin: 0 0.75rem;
  user-select: none;
}

.headerTitle {
  margin: 0 0.75rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.25;
}

.headerRight {
  flex: 1 0 0;
  display: flex;
  justify-content: flex-end;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
