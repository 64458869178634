// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/altimeter-bg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@assets/altimeter-hand0.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@assets/altimeter-hand1.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@assets/mcdu.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.scoreContainer {
  display: flex;
  flex-direction: column;
  background-color: #797d80;
  min-height: 100%;
  padding-top: 1.25rem;
}

.scoreTitle {
  flex: 0 0 auto;
  background-color: #000;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  margin: 0 auto 1.25rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25;
}

.scoreFrame {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.scoreAltimeter {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 18rem;
  height: 18rem;
  margin-bottom: 1.25rem;
  position: relative;
}

.scoreAltimeterDisplay {
  background-color: #000;
  padding: 0.125rem 0.25rem 0.125rem 0.375rem;
  border-radius: 0.25rem;
  margin-right: 2.25rem;
  overflow: hidden;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.125rem;
}

.scoreAltimeterHand {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: inherit;
  height: inherit;
  position: absolute;
  pointer-events: none;
  transition: transform 1s;
}

.scoreAltimeterHand0 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.scoreAltimeterHand1 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

.scoreMcdu {
  flex: 1 0 auto;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  width: 22.5rem;
  max-height: 35rem;
  padding: 2.75rem 3.25rem 5rem;
}

.scoreMcduDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-image: linear-gradient(#222, #000); */
  /* background-color: #000; */
  width: 16rem;
  height: 11.75rem;
  /* border-radius: 0.35rem; */
  overflow: hidden;
  position: relative;
}

.scoreMcduProgress {
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 0 1rem;
  position: relative;
}

.scoreMcduProgressPoint {
  background-color: #50c364;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.scoreMcduProgressPoint.darker {
  background-color: #286232;
}

.scoreMcduProgressBar {
  flex: 1 1 auto;
  background-color: #3b555d;
  height: 0.125rem;
  position: relative;
  overflow: hidden;
}

.scoreMcduProgressBarActive {
  background-color: #fff;
  width: inherit;
  height: inherit;
  transition: transform 1s;
}

.scoreMcduProgressIconGutter {
  position: absolute;
  top: 0;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 0;
  transition: transform 1s;
}

.scoreMcduProgressIcon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%);
  fill: #50c364;
}

.scoreMcduStats {
  margin: 0.5rem 0;
  border-spacing: 0.25rem;
  font-size: 0.8rem;
  line-height: 1.4;
  text-transform: uppercase;
}

.scoreMcduStats tr:nth-child(1) {
  color: #fff;
}

.scoreMcduStats tr:nth-child(2) {
  color: #46c3d7;
}

.scoreMcduStats tr:nth-child(3) {
  color: #50c364;
}

.scoreMcduStats tr:nth-child(4) {
  color: #50c364;
}

.scoreMcduStats td {
  vertical-align: bottom;
}

.scoreMcduStats td:first-child {
  text-align: right;
}

.scoreMcduStats td:last-child {
  min-width: 5rem;
  padding-left: 0.75rem;
}

@media (min-width: 900px) {
  .scoreContainer {
    padding: 2rem 2rem 0;
  }

  .scoreTitle {
    margin-bottom: 2rem;
  }

  .scoreFrame {
    flex-direction: row;
    align-items: stretch;
  }

  .scoreAltimeter {
    margin-right: 6rem;
    margin-bottom: 2rem;
  }

  .scoreMcdu {
    flex: 0 0 auto;
    width: 26.5rem;
    max-height: 41.25rem;
    padding: 3.25rem 3.75rem 5.5rem;
  }

  .scoreMcduDisplay {
    width: 19rem;
    height: 13.75rem;
  }

  .scoreMcduProgressPoint {
    width: 0.65rem;
    height: 0.65rem;
  }

  .scoreMcduStats {
    border-spacing: 0.25rem;
    font-size: 1rem;
  }

  .scoreMcduStats td:last-child {
    padding-left: 1rem;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
