import React from 'react';
import Markdown from 'react-markdown';

import './Text.css';

export default function Text(props) {
  return (
    <div className="textContainer">
      <Markdown className="text textMarkdown" skipHtml>
        {props.text}
      </Markdown>
    </div>
  );
}
