// import { useDebugValue, useSyncExternalStore } from 'react';
import { useDebugValue } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim/index.js';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';

export function createVar(initialValue = null) {
  let value = initialValue;
  const listeners = new Set();
  const wrapper = {
    getValue() {
      return value;
    },
    setValue(valueOrFn, patchObj = false) {
      const nextValue = typeof valueOrFn === 'function' ? valueOrFn(value) : valueOrFn;
      if (!Object.is(nextValue, value)) {
        const prevValue = value;
        if (patchObj && typeof nextValue === 'object' && nextValue !== null) {
          value = Object.assign({}, value, nextValue);
        } else {
          value = nextValue;
        }
        listeners.forEach(listener => listener(value, prevValue));
      }
    },
    subscribe(listener) {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
    dispose() {
      listeners.clear();
    },
    use() {
      const value = useSyncExternalStore(
        wrapper.subscribe,
        wrapper.getValue,
        wrapper.getValue
      );
      useDebugValue(value);
      return value;
    },
    useSelector(selector) {
      const slice = useSyncExternalStoreWithSelector(
        wrapper.subscribe,
        wrapper.getValue,
        wrapper.getValue,
        selector
      );
      useDebugValue(slice);
      return slice;
    }
  };
  Object.defineProperty(wrapper, 'value', {
    get: () => value,
    set: (value) => wrapper.setValue(value)
  });
  return wrapper;
}
