// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.textMarkdown {
  max-width: 50rem;
  flex: 1 1 auto;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
