// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.orderingItem {
  display: flex;
  background-color: #071920;
  margin: 0.5rem 0;
}

.orderingItemLabelContainer {
  flex: 1 1 auto;
  align-self: center;
  padding: 0.625rem 0.875rem;
}

.orderingItemLabel {
  margin: 0;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
}

.orderingItemBtnContainer {
  flex: 0 0 auto;
  align-self: flex-start;
  display: flex;
  padding: 0.375rem;
}

.orderingItemBtn {
  background-color: #fff;
  padding: 0.25rem;
  margin-left: 0.375rem;
  fill: #071920;
}

.orderingItemBtn:first-child {
  margin-left: 0;
}

.orderingItemBtn:disabled {
  visibility: hidden;
}

.orderingItemBtnIcon {
  width: 2.25rem;
  height: 2.25rem;
}

.orderingItemBtnIcon.rotated {
  transform: rotate(180deg);
}

@media (hover: hover) {
  .orderingItemBtn:hover {
    background-color: #556b71;
    fill: #fff;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
