import React from 'react';
import classNames from 'classnames';
import store from '@src/store/index.js';
import { boldText } from '@src/utils/react.jsx';
import * as Icon from '@src/components/Icon/Icon.jsx';
import { getCta } from './Mcq.jsx';

import './Mcq.css';

const McqIcons = [
  [Icon.RadioBtn, Icon.RadioBtnActive],
  [Icon.Checkbox, Icon.CheckboxActive]
];

export default function McqResult(props) {
  const ownAnswer = store.getAnswer();
  return (
    <div className="mcqFrame">
      <p className="activityCta">{getCta(props.multipleSelection)}</p>
      <div className="mcqOptionList">
        {props.options.map((option, i) => {
          const active = ownAnswer && ownAnswer.indexOf(i) > -1;
          const correct = props.answer.indexOf(i) > -1;
          const McqIcon = McqIcons[props.multipleSelection ? 1 : 0][active ? 1 : 0];
          return (
            <div
              key={'option-' + i}
              className={classNames(
                'mcqOption',
                active && 'active',
                correct && 'correct'
              )}
            >
              <McqIcon className="mcqOptionIcon" />
              <p className="mcqOptionLabel">{boldText(option.label)}</p>
              <div className="mcqOptionContainer">
                {correct && <Icon.Check className="mcqOptionTick" />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
