
export function noop() { }

export function clamp(n, min, max) {
  return n > min ? n < max ? n : max : min;
}

export function isPlainObject(obj) {
  return obj && typeof obj === 'object';
}

export function percent(x, fallback = 0) {
  if (!Number.isFinite(x)) return fallback;
  return Math.round(clamp(x, 0, 1) * 100);
}

export function prepad(input, char, len) {
  let str = input.toString();
  while (str.length < len) {
    str = char + str;
  }
  return str;
}

export function arrayEqual(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function preventDefault(e) {
  e.preventDefault();
}

export function formatTime(time) {
  let s = Math.floor(time);
  const h = Math.floor(s / 3600);
  s -= h * 3600;
  const m = Math.floor(s / 60);
  s -= m * 60;
  if (h === 0) {
    return `${m}:${prepad(s, '0', 2)}`;
  }
  return `${h}:${prepad(m, '0', 2)}:${prepad(s, '0', 2)}`;
}

export function formatNumber(num, maxDecimals = 3) {
  return Number.parseFloat(num.toFixed(maxDecimals)).toString();
}

export function getParamFromSearch(search, param, defaultValue) {
  search = search.trim().substring(1);
  const vars = search.split('&');
  for (let i = 0; i < vars.length; ++i) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === param) {
      return pair[1] ? decodeURIComponent(pair[1]) : '1';
    }
  }
  return defaultValue;
}

export function removeParamFromSearch(search, param) {
  const prefix = encodeURIComponent(param) + '=';
  search = search.trim().substr(1);
  if (search.length === 0) return '';
  const pars = search.split(/[&;]/g);
  for (let i = pars.length; i-- > 0;) {
    if (pars[i].lastIndexOf(prefix, 0) > -1) {
      pars.splice(i, 1);
    }
  }
  return pars.length > 0 ? '?' + pars.join('&') : '';
}

export function getQueryVariable(variable, defaultValue) {
  return getParamFromSearch(window.location.search, variable, defaultValue);
}

export function removeQueryVariable(variable) {
  const location = window.location;
  let search = location.search;
  if (getQueryVariable(search, variable)) {
    search = removeParamFromSearch(search, variable);
    const url = location.pathname + search + location.hash;
    window.history.replaceState(null, null, url);
  }
}

export function getAndRemoveQueryVariable(variable, defaultValue) {
  const value = getQueryVariable(variable, defaultValue);
  removeQueryVariable(variable);
  return value;
}

export function wait(duration = 1000) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, duration);
  });
}
