import React, { useState } from 'react';
import classNames from 'classnames';
import store from '@src/store/index.js';
import { useStateArray } from '@src/utils/hooks.js';
import Dropdown from '@src/components/Dropdown/Dropdown.jsx';
import Submit from '../Submit/Submit.jsx';
import { preventDefault } from '@src/utils/utils.js';

import './Matching.css';

export default function Matching(props) {
  const [choices, setChoice] = useStateArray(props.items.map(() => -1));
  return (
    <form className="matchingForm" onSubmit={preventDefault}>
      <p className="activityCta">Select a category for each option, then select Submit</p>
      <div className="matchingItemList">
        {props.items.map((label, i) => (
          <div className="matchingItem" key={'item-' + i}>
            <p className="matchingItemDesc">{label}</p>
            <Dropdown
              className={classNames('matchingDropdown', choices[i] > -1 && 'active')}
              options={props.categories}
              placeholder="Select an option..."
              onChange={value => setChoice(i, +value)}
            />
          </div>
        ))}
      </div>
      <Submit
        disabled={!choices.every(id => id > -1)}
        onBtnClick={() => store.submitAnswer(choices)}
      />
    </form>
  );
}
