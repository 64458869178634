import React, { useState } from 'react';
import classNames from 'classnames';
import store, { RequestStatus } from '@src/store/index.js';
import { preventDefault } from '@src/utils/utils.js';
import { boldText } from '@src/utils/react.jsx';
import * as Icon from '@src/components/Icon/Icon.jsx';
import Submit from '../Submit/Submit.jsx';

import './Mcq.css';

export function getCta(multipleSelection) {
  const text1 = multipleSelection ? 'all the options that apply' : 'one option';
  return `Select ${text1}, then select Submit`;
}

const McqIcons = [
  [Icon.RadioBtn, Icon.RadioBtnActive],
  [Icon.Checkbox, Icon.CheckboxActive]
];

export default function Mcq(props) {
  const maxOptions = props.multipleSelection ? props.options.length : 1;
  const disabled = store.answer.useSelector(answer => answer.status !== RequestStatus.INITIAL);
  const [selection, setSelection] = useState([]);
  function onOptionClick(optionId) {
    const index = selection.indexOf(optionId);
    if (index > -1) {
      selection.splice(index, 1);
    } else {
      selection.push(optionId);
    }
    setSelection(selection.slice(Math.max(selection.length - maxOptions, 0)).sort());
  }
  return (
    <form className="mcqForm" onSubmit={preventDefault}>
      <p className="activityCta">{getCta(props.multipleSelection)}</p>
      <div className="mcqOptionList">
        {props.options.map((option, i) => {
          const active = selection.indexOf(i) > -1;
          const McqIcon = McqIcons[props.multipleSelection ? 1 : 0][active ? 1 : 0];
          return (
            <button
              key={'option-' + i}
              type="button"
              className={classNames(
                'mcqOption',
                active && 'active'
              )}
              disabled={disabled}
              onClick={() => onOptionClick(i)}
            >
              <McqIcon className="mcqOptionIcon" />
              <p className="mcqOptionLabel">{boldText(option.label)}</p>
              <div className="mcqOptionContainer" />
            </button>
          );
        })}
      </div>
      <Submit
        disabled={selection.length === 0}
        onBtnClick={() => store.submitAnswer(selection)}
      />
    </form>
  );
}
