import React, { useEffect } from 'react';
import classNames from 'classnames';
import store from '@src/store/index.js';
import { percent, prepad, formatNumber } from '@src/utils/utils.js';
import * as Icon from '@src/components/Icon/Icon.jsx';

import './Score.css';

export default function Score(props) {
  const numTotal = store.roomState.useSelector(roomState => roomState.scenarioActivityCount) ?? 0;
  const score = store.score.use();

  const isTab = props.embedded;
  const { numAsked, numCorrect, scoreRate, groupScoreRate } = store.stats.use();
  const tabActive = store.tabActive.use();

  useEffect(() => {
    if (!isTab || tabActive) {
      setTimeout(() => {
        store.updateStats();
      }, isTab ? 500 : 250);
    }
  }, isTab ? [tabActive] : []);

  const { altimeter = false } = props;
  const hand0Style = { transform: `rotate(${numCorrect * 3.6}deg)` };
  const hand1Style = { transform: `rotate(${numCorrect * 36}deg)` };

  const progress = percent(numAsked / numTotal);
  const progressStyle = { transform: `translateX(${progress - 100}%)` };

  return (
    <div className={classNames('scoreContainer', props.className)}>
      {!props.embedded && <h2 className="scoreTitle">Score</h2>}
      <div className="scoreFrame">
        {altimeter && (
          <div className="scoreAltimeter">
            <div className="scoreAltimeterDisplay">{prepad(numCorrect, '0', 2)}</div>
            <div className="scoreAltimeterHand scoreAltimeterHand0" style={hand0Style} />
            <div className="scoreAltimeterHand scoreAltimeterHand1" style={hand1Style} />
          </div>
        )}
        <div className="scoreMcdu">
          <div className="scoreMcduDisplay">
            <div className="scoreMcduProgress">
              <div className={classNames('scoreMcduProgressPoint', progress === 0 && 'darker')} />
              <div className="scoreMcduProgressBar">
                <div className="scoreMcduProgressBarActive" style={progressStyle} />
              </div>
              <div className={classNames('scoreMcduProgressPoint', progress === 100 && 'darker')} />
              <div className="scoreMcduProgressIconGutter" style={progressStyle}>
                <Icon.Airplane className="scoreMcduProgressIcon" />
              </div>
            </div>
            <table className="scoreMcduStats">
              <tbody>
                <tr>
                  <td>Progress:</td>
                  <td>{`${numAsked} / ${numTotal}`}</td>
                </tr>
                <tr>
                  <td>Your score:</td>
                  <td>{`${formatNumber(numCorrect, 2)} / ${numAsked}`}</td>
                </tr>
                <tr>
                  <td>Your accuracy:</td>
                  <td>{scoreRate + '%'}</td>
                </tr>
                <tr>
                  <td>Group accuracy:</td>
                  <td>{groupScoreRate + '%'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
