// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.matchingItemList {
  display: flex;
  flex-direction: column;
}

.matchingItem {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.matchingItemDesc {
  flex: 0 0 auto;
  background-color: #071920;
  padding: 0.625rem 0.875rem;
  margin: 0;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.4;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
