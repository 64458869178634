import React from 'react';
import classNames from 'classnames';

import './Loader.css';

export default function Loader(props) {
  return (
    <div className={classNames('loader', props.className)}>
      <svg className="loaderIcon" viewBox="0 0 100 100">
        <path fill="none" d="M 90 50 A 40 40 0 1 0 15.358983848622458 70" />
      </svg>
    </div>
  );
}
