import React, { useState } from 'react';
import store from '@src/store/index.js';
import * as Icon from '@src/components/Icon/Icon.jsx';
import Submit from '../Submit/Submit.jsx';
import { clamp, preventDefault } from '@src/utils/utils.js';

import './Ordering.css';

function useOrder(initialValue) {
  const [order, setOrder] = useState(initialValue);
  const swapOrder = (index, down = false) => {
    const newIndex = clamp(index + (down ? -1 : 1), 0, order.length - 1);
    if (newIndex !== index) {
      const newOrder = order.slice();
      const tmp = newOrder[index];
      newOrder[index] = newOrder[newIndex];
      newOrder[newIndex] = tmp;
      setOrder(newOrder);
    }
  };
  return [order, swapOrder];
}

function renderCta(props) {
  let cta1 = '';
  if (props.topLabel && props.bottomLabel) {
    cta1 = ` from ${props.topLabel} to ${props.bottomLabel}`;
  }
  const cta = `Select the up/down buttons to reorder${cta1} then select Submit.`;
  return <p className="activityCta">{cta}</p>;
}

export default function Ordering(props) {
  const { items } = props;
  const [order, swapOrder] = useOrder(props.order.slice());
  return (
    <form className="orderingForm" onSubmit={preventDefault}>
      {renderCta(props)}
      <div className="orderingItemList">
        {order.map((index, i) => (
          <div key={'item-' + index} className="orderingItem">
            <div className="orderingItemLabelContainer">
              <p className="orderingItemLabel">{items[index]}</p>
            </div>
            <div className="orderingItemBtnContainer">
              <button
                className="orderingItemBtn"
                disabled={i === 0}
                onClick={() => swapOrder(i, true)}
              >
                <Icon.Triangle className="orderingItemBtnIcon rotated" />
              </button>
              <button
                className="orderingItemBtn"
                disabled={i === order.length - 1}
                onClick={() => swapOrder(i, false)}
              >
                <Icon.Triangle className="orderingItemBtnIcon" />
              </button>
            </div>
          </div>
        ))}
      </div>
      <Submit onBtnClick={() => store.submitAnswer(order)} />
    </form>
  );
}
