import React from 'react';
import store, { RoomStatus } from '@src/store/index.js';
import { percent, formatNumber } from '@src/utils/utils.js';
import * as Icon from '../Icon/Icon.jsx';
import natsLogoWhiteSvg from '@assets/nats-logo-white.svg';
import nowBoardingPng from '@assets/now-boarding.png';

import './Holding.css';

function renderInterstitialStart(scenarioTitle) {
  return (
    <div className="holdingBody holdingStart">
      {scenarioTitle && (
        <div className="holdingWelcome">
          <p className="holdingText">Welcome to the scenario:</p>
          <h2 className="holdingSubtitle">{scenarioTitle}</h2>
        </div>
      )}
      <div className="holdingContent">
        <img className="holdingGraphic" alt="Now Boarding!" src={nowBoardingPng} draggable="false" />
        <p className="holdingText">Please wait for your instructor to start the scenario</p>
      </div>
    </div>
  );
}

function renderCompletionSection(completion) {
  const { title = '', correct = 0, total = 0 } = completion;
  if (!title || total === 0) return null;
  return (
    <div className="holdingCompletion">
      <p className="holdingText">You completed the scenario:</p>
      <p className="holdingSubtitle">{title}</p>
      <br />
      <p className="holdingText">with a score of:</p>
      <p className="holdingSubtitle">{`${formatNumber(correct, 2)} / ${total}`}</p>
    </div>
  );
}

function renderInterstitialNext(completion) {
  return (
    <div className="holdingBody holdingNext">
      {renderCompletionSection(completion)}
      <div className="holdingContent">
        <img className="holdingGraphic" alt="Now Boarding!" src={nowBoardingPng} draggable="false" />
        <p className="holdingText">Please wait for your instructor to start the next scenario</p>
      </div>
    </div>
  );
}

function renderInterstitialEnd(completion) {
  return (
    <div className="holdingBody holdingEnd">
      <div className="holdingBanner">
        <Icon.Arrivals className="holdingBannerIcon" />
        <h2 className="holdingBannerTitle">Arrivals</h2>
      </div>
      {renderCompletionSection(completion)}
      <div className="holdingContent">
        <p className="holdingText">This scenario session has now ended. Please close the browser on your device and follow further instructions from your instructor.</p>
      </div>
    </div>
  );
}

function renderBody(roomStatus, scenarioTitle, completion) {
  switch (roomStatus) {
    case RoomStatus.WAIT_START: return renderInterstitialStart(scenarioTitle);
    case RoomStatus.WAIT_NEXT: return renderInterstitialNext(completion);
    case RoomStatus.WAIT_END: return renderInterstitialEnd(completion);
  }
  return renderInterstitialStart(scenarioTitle);
  // ^ display start holding screen by default
}

export default function Holding(props) {
  const roomStatus = store.roomState.useSelector(roomState => roomState.status);
  const scenarioTitle = store.roomState.useSelector(roomState => roomState.scenarioTitle);
  const completion = store.completion.use();
  return (
    <div className="holdingContainer">
      <div className="holdingFrame">
        <div className="holdingHeader">
          <img className="holdingBrandLogo" alt="NATS Logo" src={natsLogoWhiteSvg} draggable="false" />
          <h1 className="holdingTitle">ATSEP Basic Scenarios</h1>
        </div>
        {renderBody(roomStatus, scenarioTitle, completion)}
        <div className="holdingFooter" />
      </div>
    </div>
  );
}
