import React from 'react';
import classNames from 'classnames';
import store from '@src/store/index.js';
import { percent } from '@src/utils/utils.js';
import * as Icon from '@src/components/Icon/Icon.jsx';

import './Delegates.css';

export default function Delegates(props) {
  const { total = 0, ready = 0 } = store.delegates.use();
  const rate = total > 0 ? percent(ready / total, 0, 1) : 0;
  const style = { transform: `translateX(${rate - 100}%)`}
  return (
    <div className={classNames('delegatesContainer', props.className)}>
      <div className="delegatesFrame">
        <div className="delegatesValue">
          <p className="delegatesValueLabel">Delegates answering</p>
          <p className="delegatesValueCount">{total - ready}</p>
        </div>
        <div className="delegatesProgress">
          <div className="delegatesProgressBar">
            <div className="delegatesProgressBarActive" style={style} />
          </div>
          <div className="delegatesProgressIconGutter" style={style}>
            <Icon.Airplane className="delegatesProgressIcon" />
          </div>
        </div>
        <div className="delegatesValue">
          <p className="delegatesValueLabel">Delegates answered</p>
          <p className="delegatesValueCount">{ready}</p>
        </div>
      </div>
    </div>
  );
}
