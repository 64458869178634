// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.appContainer {
  width: 100%;
  height: 100%;
}

.appBuildVersion {
  background-color: #000;
  padding: 0.25rem 0.5rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  position: fixed;
  right: 16px;
  bottom: 0;
  z-index: 999;
  color: #fff;
  font-family: monospace;
  pointer-events: none;
  user-select: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
