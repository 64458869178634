import { Store } from './Store.js';
import { MockedStore } from './MockedStore.js';
export { RequestStatus, RoomStatus } from './Store.js';

function createStore() {
  // if (DEV) {
  //   return new MockedStore();
  // }
  return new Store();
}

const store = createStore();

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    store.pingWakeLock();
  }
});

if (DEV) {
  window.store = store;
}

export default store;
