// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.orderingResultSection {
  margin: 1.5rem 0;
}

.orderingResultSectionName {
  margin: 0;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.4;
}

.orderingResultFlex {
  display: flex;
  margin: 1rem 0;
}

.orderingResultBar {
  /* background-color: #50c364; */
  background-color: rgba(80, 195, 100, 0.7);
  width: 1.5rem;
  position: relative;
}

.orderingResultBar.transparent {
  background-color: transparent;
}

.orderingResultBarWrong {
  /* background-color: #d30c4d; */
  background-image: repeating-linear-gradient(-45deg, #d30c4d, #d30c4d 5px, #fff 5px, #fff 7px);
  border: 2px solid #fff;
  width: 100%;
  position: absolute;
}

.orderingResultList {
  padding-left: 2rem;
  margin: 0;
}

.orderingResultList li {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
}

.orderingResultItemIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.25rem;
  vertical-align: middle;
  fill: #72cf80;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
