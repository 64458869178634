// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/nats-belfast-sky.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.holdingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center 90%;
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  /* ^ this was enough to prevent the main navigation bar from collapsing */
}

.holdingFrame {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
  min-width: 22.5rem;
  max-width: 95%;
  min-height: 100%;
}

.holdingHeader {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  background-color: rgba(7, 25, 32, 0.95);
  padding: 2rem 1rem;
}

.holdingBrandLogo {
  height: 2rem;
  margin: 0 0.75rem;
  user-select: none;
}

.holdingTitle {
  margin: 1.5rem 0 0;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.25;
}

.holdingText {
  max-width: 20rem;
  margin: 0;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.4;
}

.holdingBody {
  flex: 0 0 auto;
}

.holdingWelcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  background-color: rgba(20, 85, 105, 0.95);
  padding: 2rem 1rem;
}

.holdingSubtitle {
  max-width: 20rem;
  margin: 0.25rem 0 0;
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.25;
}

.holdingCompletion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(20, 85, 105, 0.95);
  padding: 2rem 1rem;
}

.holdingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #28272a;
  padding: 2.5rem 1.5rem;
}

/* .holdingStart .holdingContent,
.holdingNext .holdingContent {
  padding: 1rem 1.5rem 2rem;
} */

.holdingStart .holdingContent {
  padding: 1rem 1.5rem 2rem;
}

.holdingGraphic {
  max-width: 20rem;
  margin-bottom: 0.5rem;
  user-select: none;
}

.holdingBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 0.5rem 1rem;
}

.holdingBannerIcon {
  width: 4rem;
  height: 4rem;
  fill: #fdf901;
}

.holdingBannerTitle {
  margin: 0;
  margin-left: 1.25rem;
  color: #fdf901;
  font-weight: normal;
  font-size: 1.9rem;
  line-height: 1.25;
}

.holdingFooter {
  flex: 0 1 auto;
  /* ^ this is convenient as footer
       is just a filler and doesn't
       need a scrollbar. */
  height: 10rem;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
