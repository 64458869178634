import React from 'react';
import classNames from 'classnames';
import * as Icon from '../Icon/Icon.jsx';

import './Dropdown.css';

function renderOption(option, i) {
  let value, label;
  if (typeof option === 'string') {
    value = i;
    label = option;
  } else {
    value = option.id ?? i;
    label = option.label;
  }
  return <option key={'option-' + value} value={value}>{label}</option>;
}

export default function Dropdown(props) {
  let onChange;
  if (typeof props.onChange === 'function') {
    onChange = e => props.onChange(e.currentTarget.value);
  }
  return (
    <div className={classNames('dropdownContainer', props.className)}>
      <select
        className="dropdownSelect"
        defaultValue=""
        required
        onChange={onChange}
      >
        {props.placeholder && (
          <option value="" disabled hidden>{props.placeholder}</option>
        )}
        {props.options.map(renderOption)}
      </select>
      <Icon.BasicArrow className="dropdownIcon" />
    </div>
  );
}
