// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.numericEntry {
  margin: 1.5rem 0;
}

.numericEntryQuestion {
  margin: 0 0 0.5rem;
  color: #fff;
  font-size: 1.25em;
  line-height: 1.4;
}

.numericEntryQuestion.noMargin {
  margin: 0;
}

.numericEntryValues {
  margin: 0;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.4;
}

.numericEntryValues span {
  margin: 0 0.5rem;
}

.numericEntryInput {
  background-color: #fff;
  width: 5.5rem;
  padding: 0.5rem;
  border: none;
  color: #000;
  font-size: 1.5rem;
  text-align: right;
  line-height: 1.4;
}

/* Chrome, Safari, Edge, Opera */
.numericEntryInput::-webkit-outer-spin-button,
.numericEntryInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numericEntryInput {
  -moz-appearance: textfield;
}

.numericEntryTable {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
}

.numericEntryTable strong {
  font-weight: bold;
}

.numericEntryTable td:first-child {
  text-align: right;
}

.numericEntryTable td:nth-child(2) {
  padding-left: 1rem;
}

.numericResultEntryIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.25rem;
  vertical-align: middle;
  fill: #72cf80;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
