import React from 'react';
import classNames from 'classnames';
import natsLogoWhiteSvg from '@assets/nats-logo-white.svg';

import './Header.css';

export default function Header(props) {
  return (
    <div className={classNames('headerContainer', props.className)}>
      <header className="headerFrame">
        <div className="headerLeft">
          <img className="headerBrandLogo" alt="NATS Logo" src={natsLogoWhiteSvg} draggable="false" />
        </div>
        <div className="headerMiddle">
          <h1 className="headerTitle">{props.title}</h1>
        </div>
        <div className="headerRight">
        </div>
      </header>
    </div>
  );
}
