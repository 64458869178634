import { useState, useRef, useEffect } from 'react';

export function useDebugBool(initialValue = false) {
  const [bool, setBool] = useState(initialValue);
  const timerRef = useRef(null);
  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);
  function toggle(value) {
    if (value !== undefined) {
      setBool(value);
    } else {
      setBool(bool => !bool);
    }
  }
  function set(value, duration = 0) {
    clearTimeout(timerRef.current);
    if (duration > 0) {
      timerRef.current = setTimeout(toggle, duration);
    }
    toggle(value);
  }
  return [bool, set];
}

export function useStateArray(initialValue = []) {
  const [state, setState] = useState(initialValue);
  const setStateItem = (index, value) => {
    if (state[index] !== value) {
      const newState = state.slice();
      newState[index] = value;
      setState(newState);
    }
  };
  return [state, setStateItem];
}

export function useInterval(callback, duration) {
  useEffect(() => {
    const intv = setInterval(callback, duration);
    return () => {
      clearInterval(intv);
    };
  }, []);
}
