import React, { useEffect } from 'react';
import classNames from 'classnames';
import store from '@src/store/index.js';
import Header from './Header/Header.jsx';
import Text from './Text/Text.jsx';
import Activity from './Activity/Activity.jsx';
import Score from './Score/Score.jsx';
import * as Icon from '@src/components/Icon/Icon.jsx';

import './Scenario.css';

function renderScreen(roomState) {
  const {
    progress,
    taskProgress,
    activityProgress,
    data
  } = roomState;
  if (progress === 0 || progress === 2) {
    return (
      <Text className="scenarioContent" {...data} />
    );
  } if (progress === 1) {
    if (taskProgress === 0) {
      return (
        <Activity
          className="scenarioContent"
          {...data}
          result={activityProgress === 1}
        />
      );
    }
  }
  return (
    <Score className="scenarioContent" />
  );
}

function shouldShowTab(roomState) {
  return (
    roomState.progress === 1 &&
    roomState.taskProgress === 0 &&
    roomState.activityProgress === 1
  );
}

function renderTab(tabActive) {
  return (
    <div
      className={classNames('scenarioTab', tabActive && 'active')}
      onClick={() => store.toggleTab()}
    >
      <div className="scenarioTabBanner">
        <svg className="scenarioTabBannerBg" viewBox="0 0 124 28">
          <path d="M10 0h104c2.41 0 4.475 1.993 4.976 4.351L124 28H0L5.024 4.351C5.525 1.993 7.59 0 10 0z"/>
        </svg>
        <div className="scenarioTabBannerBox">
          <h2 className="scenarioTabBannerTitle">Score</h2>
          <Icon.Arrow className="scenarioTabBannerIcon" />
        </div>
      </div>
      <Score className="scenarioTabContent" embedded />
    </div>
  );
}

export default function Scenario(props) {
  const roomState = store.roomState.use();
  const tabActive = store.tabActive.use();
  const showTab = shouldShowTab(roomState);
  useEffect(() => window.scrollTo(0, 0), [store.getScreenKey()]);
  return (
    <div className={classNames('scenarioContainer', showTab && 'extraPadding')}>
      <Header className="scenarioHeader" title={roomState.scenarioTitle} />
      {renderScreen(roomState)}
      {showTab && renderTab(tabActive)}
    </div>
  );
}
