import React from 'react';
import store from '@src/store/index.js';
import * as Icon from '@src/components/Icon/Icon.jsx';

import './MatchingResult.css';

function isValidAnswer(answer, index, categories) {
  return answer && answer[index] !== undefined && answer[index] > -1;
}

export default function MatchingResult(props) {
  const { categories, items, answer } = props;
  const ownAnswer = store.getAnswer();
  return (
    <div className="matchingResultFrame">
      {items.map((label, i) => (
        <div key={'item-' + i} className="matchingResultItem">
          <p className="matchingResultItemLabel">{label}</p>
          {isValidAnswer(ownAnswer, i) && (
            <p className="matchingResultItemAnswer">
              <span>You said: {categories[ownAnswer[i]]}</span>
              {ownAnswer[i] === answer[i] && (
                <Icon.CheckInline className="matchingResultItemIcon" />
              )}
            </p>
          )}
          <p className="matchingResultItemResult">Answer: {categories[answer[i]]}</p>
        </div>
      ))}
    </div>
  );
}
