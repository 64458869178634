// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/nats-belfast-sky.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.connectContainer {
  display: flex;
  flex-direction: column;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center 90%;
  background-size: cover;
  background-attachment: fixed;
  padding: 2rem 0;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
  /* ^ this was enough to prevent the main navigation bar from collapsing */
}

.connectFrame {
  display: flex;
  flex-direction: column;
  width: 33%;
  min-width: 22.5rem;
  max-width: 95%;
  margin: auto;
}

.connectHeader {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(7, 25, 32, 0.95);
  padding: 3rem 0.5rem 2.5rem;
}

.connectBrandLogo {
  height: 2rem;
  margin: 0 0.75rem;
  user-select: none;
}

.connectTitle {
  margin: 1.5rem 0 0;
  color: #fff;
  font-size: 1.7rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.25;
}

.connectTitle strong {
  font-weight: 500;
}

.connectBody {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(20, 85, 105, 0.95);
  padding: 2rem 1rem;
}

.connectCta {
  width: 18.5rem;
  margin: 0;
  color: #fff;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.3;
}

.connectInput {
  background-color: #fff;
  width: 16rem;
  height: 4rem;
  border: 0;
  border-radius: 2.5rem;
  margin: 1.5rem 0;
  color: #071920;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
}

.connectInput::placeholder {
  position: relative;
  top: -0.25rem;
  color: rgba(7, 25, 32, 0.6);
  font-size: 1.25rem;
  text-transform: none;
  letter-spacing: normal;
}

.connectSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3.5rem;
  margin: 0.5rem 0;
}

.connectBtn {
  width: 13rem;
  cursor: pointer;
}

.connectLoader {
  width: 2.5rem;
  height: 2.5rem;
}

.connectFooter {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 7rem;
  background-color: #071920;
  padding: 1rem 2rem;
}

.connectFooter.hidden {
  flex: 0 1 auto;
  /* ^ attempt at disabling the scrollbar
       when footer is hidden. */
}

.connectMessage {
  margin: 0;
  color: #ff8080;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.3;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
