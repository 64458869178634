import React from 'react';
import store, { RequestStatus } from '@src/store/index.js';
import Submit from '../Submit/Submit.jsx';
import { useStateArray } from '@src/utils/hooks.js';
import { preventDefault } from '@src/utils/utils.js';

import './Numeric.css';

function isNumberValid(num) {
  return typeof num === 'number' && Number.isFinite(num);
}

function isGuessValid(guess, unit) {
  return (
    Array.isArray(guess) &&
    guess.length === unit.length &&
    isNumberValid(guess[0]) &&
    (unit.length === 1 || isNumberValid(guess[1]))
  );
}

export function areGuessesValid(guesses, entries) {
  if (!Array.isArray(guesses)) return false;
  for (let i = 0; i < entries.length; ++i) {
    if (!isGuessValid(guesses[i], entries[i].unit)) {
      return false;
    }
  }
  return true;
}

function renderEntry(id, side, unit, updateEntry, disabled = false) {
  return (
    <>
      <input
        type="number"
        className="numericEntryInput"
        data-id={id}
        data-side={side}
        onKeyUp={updateEntry}
        onChange={updateEntry}
        disabled={disabled}
      />
      {unit && <span>{unit}</span>}
    </>
  );
}

export default function Numeric(props) {
  const { entries } = props;
  const [guesses, setGuesses] = useStateArray([]);
  const disabled = store.answer.useSelector(answer => answer.status !== RequestStatus.INITIAL);
  function updateEntry(e) {
    const input = e.currentTarget;
    const id = parseInt(input.dataset.id);
    const side = parseInt(input.dataset.side);
    let guess = guesses[id];
    guess = guess ? guess.slice() : [];
    guess[side] = parseFloat(input.value);
    setGuesses(id, guess);
  }
  return (
    <form className="numericFrame" onSubmit={preventDefault}>
      <p className="activityCta">Complete the numeric entries, then select Submit</p>
      <div className="numericEntryList">
        {entries.map((entry, i) => (
          <div key={'entry-' + i} className="numericEntry">
            <p className="numericEntryQuestion">{entry.question}</p>
            <p className="numericEntryValues">
              {renderEntry(i, 0, entry.unit[0], updateEntry, disabled)}
              {entry.unit.length > 1 && (
                <>
                  <span>to</span>
                  {renderEntry(i, 1, entry.unit[1], updateEntry, disabled)}
                </>
              )}
            </p>
          </div>
        ))}
      </div>
      <Submit
        disabled={!areGuessesValid(guesses, entries)}
        onBtnClick={() => store.submitAnswer(guesses)}
      />
    </form>
  );
}
