import React from 'react';

export function Airplane(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M31.542 44h19.247L39.77 17.67c-.606-1.45-.037-2.67 1.185-2.67h4.184c2.255 0 3.918 1.615 5.05 3.245L68.313 44h12.124c7.386 0 7.327 12 0 12H68.312L50.19 80.975C48.643 83.105 47.025 85 45.138 85h-4.184c-1.178 0-1.747-1.034-1.184-2.425L50.79 56H31.541l-4.59 7.225C25.054 66.216 24.074 68 21.77 68h-3.794c-1.967 0-2.285-1.485-1.468-3.537l4.428-11.122c1.164-2.923 1.075-3.395 0-6.213l-4.428-11.61C15.739 33.501 16.109 32 17.975 32h3.794c2.494 0 3.299 1.849 4.61 3.972z"/>
    </svg>
  );
}

export function Arrivals(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M23 5.75C13.465 5.75 5.75 13.465 5.75 23v54c0 9.535 7.715 17.25 17.25 17.25h54c9.535 0 17.25-7.715 17.25-17.25V23c0-9.535-7.715-17.25-17.25-17.25H23zm0 2.5h54A14.69 14.69 0 0 1 91.75 23v54A14.69 14.69 0 0 1 77 91.75H23A14.69 14.69 0 0 1 8.25 77V23A14.69 14.69 0 0 1 23 8.25zm33.411 16.867c-1.054 0-2.13.529-2.706 1.577L41.223 49.407c-.318.578-.958.94-1.84.935-6.193-.038-12.358.029-18.55.36-6.176.33-6.403 8.468 0 8.469h30.51c15.208 0 19.127-1.21 26.975-3.627 0 0 1.37-.685 1.55-1.357l2.535-9.486c-9.5-2.324-6.183 6.088-12.18 5.795-4.626-.226-9.19-.272-13.766-.272-.39 0-.558-.265-.498-.483l6.628-23.877c.152-.548-.304-.747-.848-.747H56.41zM22.945 60.5a1.25 1.25 0 0 0-1.25 1.25 1.25 1.25 0 0 0 1.25 1.25 1.25 1.25 0 0 0 1.25-1.25 1.25 1.25 0 0 0-1.25-1.25zm23.845 0a1.25 1.25 0 0 0-1.25 1.25A1.25 1.25 0 0 0 46.79 63a1.25 1.25 0 0 0 1.25-1.25 1.25 1.25 0 0 0-1.25-1.25zm3.57 0a1.25 1.25 0 0 0-1.25 1.25A1.25 1.25 0 0 0 50.36 63a1.25 1.25 0 0 0 1.25-1.25 1.25 1.25 0 0 0-1.25-1.25zm-25.136 7.62c-1.24 0-2.238 1-2.238 2.24 0 1.24.998 2.239 2.238 2.239h43.012c1.24 0 2.239-.999 2.239-2.239 0-1.24-.999-2.24-2.239-2.24H25.224z"/>
    </svg>
  );
}

export function Arrow(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path fill="none" strokeLinecap="round" d="M50 22.5V81M24 48.5l26-26 26 26"/>
    </svg>
  );
}

export function BasicArrow(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path fill="none" strokeLinecap="round" strokeLinejoin="round" d="M31.5 42L50 60.5 68.5 42"/>
    </svg>
  );
}

export function Check(props) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <path d="M15.33 35.1c-.56.74-.42 1.78.3 2.34l10.29 7.89c.73.56 1.77.42 2.33-.31l18.06-23.54c.57-.73.43-1.77-.3-2.33L42.3 16.3a1.66 1.66 0 0 0-2.33.3l-14.2 18.52-5.26-4.03a1.66 1.66 0 0 0-2.33.3z"/>
    </svg>
  );
}

export function CheckInline(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M12.5 60.8a4 4 0 0 0 .7 5.6L37.6 85a4 4 0 0 0 5.6-.7L86 28.5a4 4 0 0 0-.7-5.5l-8.8-6.8a4 4 0 0 0-5.5.7L37.3 61l-12.5-9.6a4 4 0 0 0-5.5.7z"/>
    </svg>
  );
}

export function Loader(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path fill="none" d="M 90 50 A 40 40 0 1 0 15.358983848622458 70"/>
    </svg>
  );
}

export function Triangle(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M50 70.465L29.2 34.44h41.6z"/>
    </svg>
  );
}

export function Checkbox(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M24 20a4 4 0 0 0-4 4v52a4 4 0 0 0 4 4h52a4 4 0 0 0 4-4V24a4 4 0 0 0-4-4zm4 8h44v44H28z" color="#000"/>
    </svg>
  );
}

export function CheckboxActive(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M24 20a4 4 0 0 0-4 4v52a4 4 0 0 0 4 4h52a4 4 0 0 0 4-4V24a4 4 0 0 0-4-4H24zm4 8h44v44H28V28zm10 10v24h24V38H38z"/>
    </svg>
  );
}

export function RadioBtn(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M50 20c-16.521 0-30 13.479-30 30s13.479 30 30 30 30-13.479 30-30-13.479-30-30-30zm0 8c12.198 0 22 9.802 22 22s-9.802 22-22 22a21.94 21.94 0 0 1-22-22c0-12.198 9.802-22 22-22z"/>
    </svg>
  );
}

export function RadioBtnActive(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M50 20c-16.521 0-30 13.479-30 30s13.479 30 30 30S80 66.52 80 50 66.52 20 50 20zm0 8c12.198 0 22 9.802 22 22s-9.802 22-22 22a21.94 21.94 0 0 1-22-22c0-12.198 9.802-22 22-22zm0 8a14 14 0 0 0-14 14 14 14 0 0 0 14 14 14 14 0 0 0 14-14 14 14 0 0 0-14-14z"/>
    </svg>
  );
}
