// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dropdownContainer {
  min-width: 6rem;
  height: 3rem;
  position: relative;
  color: #071920;
}

.dropdownContainer.active {
  color: #fff;
}

.dropdownSelect {
  background-color: #e8e9eb;
  width: 100%;
  height: 100%;
  padding: 0 3rem 0 0.875rem;
  border: none;
  color: currentcolor;
  font-size: 1.25rem;
  line-height: 1.25;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.dropdownSelect:invalid {
  color: #556b71;
}

.active > .dropdownSelect {
  background-color: #556b71;
}

.dropdownIcon {
  width: 2.25rem;
  height: 100%;
  position: absolute;
  right: 0.25rem;
  stroke: currentcolor;
  stroke-width: 8;
  pointer-events: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
