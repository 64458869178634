// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mcqOptionList {
  display: flex;
  flex-direction: column;
}

.mcqOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 0.25rem solid transparent;
  margin: 0.5rem 0;
  color: #071920;
  fill: currentcolor;
}

.mcqOption:disabled {
  opacity: 1;
  pointer-events: all;
  user-select: text;
  cursor: auto;
}

.mcqOption.active {
  background-color: #556b71;
  color: #fff;
}

.mcqOption.correct {
  border-color: #46c3d7;
}

.mcqOptionIcon {
  flex: 0 0 auto;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0.125rem;
}

.mcqOptionLabel {
  flex: 1 1 auto;
  margin: 0.5rem 0.75rem 0.5rem 0.25rem;
  font-size: 1.25rem;
  text-align: left;
  line-height: 1.4;
}

.mcqOptionContainer {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  margin: 0.25rem;
}

.mcqOptionTick {
  background-color: #071920;
  border-radius: 1.25rem;
  fill: #fff;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
