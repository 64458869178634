// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.activityContainer {
  /* start bottom element fix */
  display: flex;
  /* flex-direction: column; */
  /* end bottom element fix */
}

.activityFrame {
  /* start bottom element fix */
  display: flex;
  flex-direction: column;
  /* end bottom element fix */
  width: 36rem;
  padding: 1.5rem;
  margin: 0 auto;
}

.activityTitle {
  margin: 0 0 0.25rem;
  color: #46c3d7;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.25;
}

.activitySubtitle {
  margin: 0 0 1.25rem;
  color: #46c3d7;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.25;
}

.activityMarkdown {
  margin-bottom: 1rem;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
}

.activityMarkdown > p {
  margin: 0.75rem 0;
}

.activityCta {
  margin: 1rem 0;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.4;
}

.activitySubmit {
  margin: 1.5rem 0;
}

.activityDelegates {
  margin-top: auto;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
