import * as Colyseus from 'colyseus.js';
import { createVar } from '@src/utils/var.js';
import { noop, isPlainObject, percent, getQueryVariable } from '@src/utils/utils.js';

export const RequestStatus = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2
};
// ^ in case of error, request is INITIAL
//   while error is a non-empty string

export const RoomStatus = {
  SCENARIO: 0,
  WAIT_START: 1,
  WAIT_NEXT: 2,
  WAIT_END: 3
};

// Always increase this number when making changes
// to the data structure stored in the localStorage
const storageFormatVersion_ = 2;

let warnedAboutWakeLock_ = false;

function useNewAnswersFormat(answers) {
  if (!isPlainObject(answers)) return false;
  return Object.values(answers).every(entry => {
    return (
      entry.length === 2 &&
      Array.isArray(entry[0]) &&
      (entry[1] === 0 || entry[1] === 1)
    );
  });
}

export class Store {
  constructor() {
    this.serverUrl = SERVER_URL;
    this.client = new Colyseus.Client(this.serverUrl);
    this.isTest = DEBUG && getQueryVariable('test');
    this.testEnd = 0;

    // Code suggested after a disconnect
    this.suggestedCode = '';

    this.connection = createVar({
      status: RequestStatus.INITIAL,
      error: ''
    });
    this.room = null;
    this.sessionId = '';
    this.wakeLock = null;
    this.wakeLockTimer = null;

    this.delegates = createVar({
      total: 0,
      ready: 0
    });

    this.roomState = createVar({});

    this.answer = createVar({
      status: RequestStatus.INITIAL,
      error: ''
    });
    // A number attached to the answer so we
    // discard the result of old answers
    // seqNum = N means that we have sent the
    // answer N and any result whose seqNum
    // not N can be discarded.
    this.answerSeqNum = -1;
    // History of given answers
    // "{taskId}.{activityId}": [answer, correct]
    this.answers = {};

    // Cached answer key (e.g. "1.2")
    // and data (e.g. [0, 1])
    // this answer will be moved into 'answers'
    // once the server has acknowledged it.
    this.answerKey = null;
    this.answerData = null;

    this.score = createVar({
      correct: 0,
      total: 0
    });

    // Only updated once updateStats() is called
    // Used to delay animations in the score screen
    this.stats = createVar({
      numAsked: 0,
      numCorrect: 0,
      scoreRate: 0,
      groupScoreRate: 100
    });

    // completed scenario and score
    this.completion = createVar({
      title: '',
      correct: 0,
      total: 0
    });

    this.tabActive = createVar(false);
  }

  isConnected() {
    return this.connection.value.status === RequestStatus.LOADED;
  }

  isInSession() {
    return this.isConnected() && !!this.sessionId;
  }

  async connect(code) {
    if (this.connection.value.status !== RequestStatus.INITIAL) return;
    try {
      this.connection.setValue({ status: RequestStatus.LOADING }, true);
      await this.connectInternal(code);
      this.connection.value = {
        status: RequestStatus.LOADED,
        error: ''
      };
      this.suggestedCode = code;
    } catch (err) {
      const invalidCode = err.code === 4212;
      let message = err.message;
      if (invalidCode) {
        message = 'Sorry that code is not valid. Please check you have entered it correctly!';
      } else if (message === 'offline') {
        message = 'Sorry, the server is currently unavailable';
      }
      this.connection.value = {
        status: RequestStatus.INITIAL,
        error: message
      };
      this.suggestedCode = '';
      if (!invalidCode) throw err;
    }
  }

  async connectInternal(code) {
    const room = await this.client.joinById(code);

    room.onMessage('state', state => this.setRoomState(state));
    room.onMessage('result', result => this.onAnswerResult(result));
    room.onMessage('D', ([total, ready]) => this.delegates.value = { total, ready });
    // room.onMessage('__playground_message_types', noop);
    room.onLeave((code) => {
      console.log(`Room onLeave (code: ${code})`);
      let message = '';
      if (code === 4000) message = 'You have been disconnected';
      this.disconnect(message);
    });
    room.onError((code, message) => {
      console.log(`Room onError: ${message} (code: ${code})`);
      this.disconnect();
    });

    // Keep Alive pings
    // (Cloudflare closes WebSocket connections after 100 seconds of inactivity)
    const pingInterval = setInterval(() => {
      if (room.connection.isOpen) {
        room.send('P');
      } else {
        clearInterval(pingInterval);
      }
    }, 5000);

    this.room = room;
  }

  async disconnect(err) {
    await this.disconnectInternal();
    this.clear(err);
  }

  async disconnectInternal() {
    if (this.room) {
      try {
        this.room.leave();
      } catch (err) {
        console.error(err);
      }
    }
  }

  async pingWakeLock() {
    if (!this.isConnected()) return;
    try {
      if (!this.wakeLock) {
        this.wakeLock = await navigator.wakeLock.request('screen');
        this.wakeLock.addEventListener('release', () => {
          if (DEV) console.log('WakeLock was released');
          if (this.wakeLockTimer) {
            clearTimeout(this.wakeLockTimer);
            this.wakeLockTimer = null;
          }
          this.wakeLock = null;
        });
        if (DEV) console.log('WakeLock acquired');
      }
      if (this.wakeLockTimer) {
        clearTimeout(this.wakeLockTimer);
      }
      this.wakeLockTimer = setTimeout(() => this.wakeLock.release(), 9e5);
    } catch (err) {
      if (!warnedAboutWakeLock_) {
        console.warn('WakeLock acquisition failed:', err);
        warnedAboutWakeLock_ = true;
      }
    }
  }

  setRoomState(roomState = {}) {
    const { sessionId = '' } = roomState;
    // flag the latest score as seen
    if (this.isInResult()) {
      this.updateStats();
    }
    const wasInScenario = this.roomState.value.status === RoomStatus.SCENARIO;
    const isInScenario = roomState.status === RoomStatus.SCENARIO;
    // update completion when leaving a completed scenario
    if (wasInScenario && !isInScenario) {
      this.updateCompletion();
    }
    // update room state
    this.roomState.value = roomState;
    if (sessionId !== this.sessionId) {
      this.sessionId = sessionId;
      this.clearSession();
      // attempt to retrieve existing session
      if (sessionId) this.load();
    } else if (this.isInQuestion()) {
      this.clearAnswer();
    }
    // collapse score tab
    this.toggleTab(false);
    this.pingWakeLock();
  }

  isInScenario() {
    if (!this.isConnected()) return false;
    const roomState = this.roomState.value;
    return roomState && roomState.status === RoomStatus.SCENARIO;
  }

  isLastActivityOfTask() {
    const roomState = this.roomState.value;
    return roomState && roomState.activityId >= roomState.activityCount - 1;
  }

  isInActivity() {
    if (!this.isInScenario()) return false;
    const roomState = this.roomState.value;
    return roomState.progress === 1 && roomState.taskProgress === 0;
  }

  isInQuestion() {
    return this.isInActivity() && this.roomState.value.activityProgress === 0;
  }

  isInResult() {
    return this.isInActivity() && this.roomState.value.activityProgress === 1;
  }

  getScreenKey() {
    if (!this.isInScenario()) return '';
    const roomState = this.roomState.value;
    const key = [roomState.progress];
    if (roomState.progress === 1) {
      key.push(roomState.taskId);
      if (roomState.taskProgress === 0) {
        key.push(roomState.activityId);
      }
    }
    return key.join('.');
  }

  getActivityKey() {
    if (!this.isInActivity()) return '';
    const roomState = this.roomState.value;
    return roomState.taskId + '.' + roomState.activityId;
  }

  getAnswer() {
    const activityKey = this.getActivityKey();
    if (!activityKey) return null;
    const entry = this.answers[activityKey];
    if (!entry || !Array.isArray(entry[0])) return null;
    return entry[0];
  }

  getNumCorrectAnswers(fullScenario = false) {
    let taskId = 255;
    let activityId = 255;
    if (!fullScenario) {
      const roomState = this.roomState.value;
      taskId = roomState.taskId;
      // NOTE: During the scenario outcome, taskId is -1
      //       but we still want to take all the tasks
      //       into accounts to compute the score.
      if (roomState.progress < 2) {
        taskId = roomState.taskId;
      }
      // NOTE: Same thing with the task outcome/outro.
      if (roomState.taskProgress === 0) {
        activityId = roomState.activityId;
      }
    }
    const correct = Object.keys(this.answers).reduce((ret, key) => {
      const [t, a] = key.split('.').map(e => parseInt(e));
      if (t < taskId || t === taskId && a <= activityId) {
        const entry = this.answers[key];
        if (entry[1] === 0 || entry[1] === 1) ret += entry[1];
      }
      return ret;
    }, 0);
    return !isNaN(correct) ? correct : this.score.value.correct;
  }

  submitAnswer(answer, event) {
    if (!this.isInQuestion()) return;
    if (this.answer.value.status !== RequestStatus.INITIAL) return;
    const activityKey = this.getActivityKey();
    if (activityKey) {
      this.answerKey = activityKey;
      this.answerData = answer;
    } else {
      this.answerKey = null;
      this.answerData = null;
    }
    this.submitAnswerInternal(answer, activityKey);
    this.answer.setValue({ status: RequestStatus.LOADING }, true);
  }

  submitAnswerInternal(answer, activityKey) {
    if (!this.room) return;
    const sn = ++this.answerSeqNum;
    this.room.send('answer', { sn, key: activityKey, data: answer });
  }

  onAnswerResult(result) {
    const { sn, err, data } = result;
    if (sn !== this.answerSeqNum) {
      console.warn('Result sequence number mismatch: received %d, expected %d', sn, this.answerSeqNum);
      return;
    }
    if (err === 0) {
      this.onAnswerSuccess(data);
    } else {
      this.onAnswerError(data);
    }
    this.pingWakeLock();
  }

  // The answer has been approved by the server,
  // it can now be stored locally in the answers history
  onAnswerSuccess(correct) {
    // NOTE: Here we should probably receive results
    //       even when status is INITIAL
    if (this.answer.value.status !== RequestStatus.LOADING) return;
    this.answer.value = {
      status: RequestStatus.LOADED,
      error: ''
    };
    if (!this.answerKey || !Array.isArray(this.answerData)) {
      console.error('Answer query succeeded but no answer cached');
      return;
    }
    this.answers[this.answerKey] = [this.answerData, correct ? 1 : 0];
    this.answerKey = null;
    this.answerData = null;

    const score = this.score.value;
    this.score.value = {
      correct: score.correct + (correct ? 1 : 0),
      total: score.total + 1
    };

    this.save();
  }

  onAnswerError(error = '') {
    if (this.answer.value.status !== RequestStatus.LOADING) return;
    this.answer.value = {
      status: RequestStatus.INITIAL,
      error
    };
    this.answerKey = null;
    this.answerData = null;

    console.error('Answer error: %s', error);
  }

  clearAnswer() {
    this.answer.value = {
      status: RequestStatus.INITIAL,
      error: ''
    };
    this.answerKey = null;
    this.answerData = null;
  }

  toggleTab(active = !this.tabActive.value) {
    this.tabActive.value = active;
  }

  updateStats() {
    const {
      scenarioActivityId = -1,
      groupScoreRate = 0
    } = this.roomState.value;
    const numAsked = scenarioActivityId + 1;
    const numCorrect = Math.min(this.getNumCorrectAnswers(), numAsked);

    const { correct, total } = this.score.value;
    const scoreRate = percent(correct / total);
    this.stats.value = {
      numAsked,
      numCorrect,
      scoreRate,
      groupScoreRate
    };

    if (DEV) {
      console.log('updateStats', this.stats.value.numAsked);
    }
  }

  clearScoreAndStats() {
    this.score.value = {
      correct: 0,
      total: 0
    };
    this.updateStats();
  }

  updateCompletion() {
    let title = '';
    let correct = 0;
    let total = 0;

    if (this.isInScenario()) {
      const roomState = this.roomState.value;
      if (roomState.completed) {
        title = roomState.scenarioTitle;
        total = roomState.scenarioActivityCount;
        correct = Math.min(this.getNumCorrectAnswers(true), total);
      }
    }
    this.completion.value = {
      title,
      correct,
      total
    };
  }

  clearSession() {
    this.clearAnswer();
    this.answers = {};
    this.clearScoreAndStats();
    this.toggleTab(false);
  }

  clear(error = '') {
    this.connection.value = {
      status: RequestStatus.INITIAL,
      error
    };
    this.room = null;
    this.sessionId = '';

    this.delegates.value = {
      total: 0,
      ready: 0
    };

    this.roomState.value = {};

    this.clearSession();
  }

  async createTestRoom() {
    if (this.connection.value.status !== RequestStatus.INITIAL) return;
    this.testEnd = 0;
    try {
      this.client.auth.token = 'devtoken';
      this.connection.setValue({ status: RequestStatus.LOADING }, true);
      this.room = await this.client.create('scenario');
      this.testEnd = Date.now() + 9e5;
      this.room.onError(async (code, message) => {
        this.testEnd = 0;
        this.connection.setValue({ error: `${message} (${code})` }, true);
        try {
          await this.room.leave();
        } catch (err) {
          console.error(err);
        } finally {
          this.connection.value = {
            status: RequestStatus.INITIAL,
            error: message
          };
        }
      });
      this.room.onLeave((code) => {
        this.testEnd = 0;
        this.connection.value = {
          status: RequestStatus.INITIAL,
          error: `Disconnected (${code})`
        };
      });
      // this.room.onMessage('__playground_message_types', noop);
      this.connection.value = {
        status: RequestStatus.LOADED,
        error: ''
      };
    } catch (err) {
      this.connection.value = {
        status: RequestStatus.INITIAL,
        error: err.message
      };
      throw err;
    }
  }

  async closeTestRoom() {
    if (this.connection.value.status !== RequestStatus.LOADED || !this.room) return;
    this.testEnd = 0;
    try {
      this.connection.setValue({ status: RequestStatus.LOADING }, true);
      await this.room.leave();
      this.connection.value = {
        status: RequestStatus.INITIAL,
        error: ''
      };
    } catch (err) {
      this.connection.value = {
        status: RequestStatus.INITIAL,
        error: err.message
      };
      throw err;
    }
  }

  getTestTime() {
    if (this.testEnd === 0) return -1;
    return Math.max(Math.round(this.testEnd - Date.now()) / 1000, 0);
  }

  isStateRecoverable(state) {
    if (!this.isInSession()) return false;
    const { scenarioId, scenarioRevision } = this.roomState.value;
    return (
      state.ver === storageFormatVersion_ &&
      state.scenarioId === scenarioId &&
      state.scenarioRevision === scenarioRevision &&
      state.sessionId === this.sessionId &&
      state.score &&
      useNewAnswersFormat(state.answers)
    );
  }

  serialize() {
    if (!this.isInSession()) return '';
    const { scenarioId, scenarioRevision } = this.roomState.value;
    const state = {
      sessionId: this.sessionId,
      ver: storageFormatVersion_,
      scenarioId,
      scenarioRevision,
      score: this.score.value,
      answers: this.answers
    };
    return JSON.stringify(state);
  }

  deserialize(data) {
    let state;
    try {
      state = JSON.parse(data);
    } catch (err) {
      throw new Error('Invalid JSON');
    }
    if (this.isStateRecoverable(state)) {
      this.score.value = state.score;
      this.answers = state.answers;
    } else {
      throw new Error('State not recoverable');
    }
  }

  load() {
    if (this.isInSession() && localStorage) {
      const state = localStorage.getItem(this.sessionId);
      if (state) {
        try {
          this.deserialize(state);
        } catch (err) {
          console.warn('Failed to recover existing state: %s', err);
        }
      }
    }
  }

  save() {
    if (this.isInSession() && localStorage) {
      const data = this.serialize();
      localStorage.setItem(this.sessionId, data);
    }
  }
}
