// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html { font-size: 12px; }
@media (min-width: 360px) and (max-width: 699px) { html { font-size: 14px; } }
@media (min-width: 700px) and (max-width: 999px) { html { font-size: 16px; } }
@media (min-width: 1000px) and (max-width: 1499px) { html { font-size: 18px; } }
@media (min-width: 1500px) and (max-width: 1999px) { html { font-size: 20px; } }
@media (min-width: 2000px) and (max-width: 2149px) { html { font-size: 22px; } }
@media (min-width: 2150px) and (max-width: 2299px) { html { font-size: 24px; } }
@media (min-width: 2300px) and (max-width: 2449px) { html { font-size: 26px; } }
@media (min-width: 2450px) and (max-width: 2599px) { html { font-size: 28px; } }
@media (min-width: 2600px) { html { font-size: 30px; } }

body {
  color: #fff;
}

button {
  display: inline-block;
  background: transparent;
  width: auto;
  padding: 0;
  border: none;
  border-radius: 0;
  margin: 0;
  overflow: hidden;
  color: inherit;
  font: inherit;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:disabled,
input:disabled {
  opacity: 0.5;
  pointer-events: none;
}

td {
  padding: 0;
}

svg {
  display: inline-block;
  width: 100%;
  height: 100%;
}

button > svg {
  vertical-align: middle;
}

::selection {
  /* background-color: #184b63; */
  background-color: #1c5672;
  color: #fff;
}

.null {
  display: none;
}

.hidden {
  visibility: hidden;
}

.btn {
  background-color: #50c364;
  padding: 0.625rem 1.5rem;
  border: 2px solid #caedd0;
  color: #071920;
  font-size: 1.35rem;
  transition: transform 100ms;
}

.btn:hover {
  background-color: #58d76e;
  transform: scale(1.025);
}

.btnLarge {
  padding: 0.625rem 2.5rem;
}

.btnXLarge {
  padding: 0.625rem 3.75rem;
}

.text > :first-child {
  margin-top: 0;
}

.text > :last-child {
  margin-bottom: 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
