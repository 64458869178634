// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.scenarioContainer {
  /* start bottom element fix */
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* end bottom element fix */
}

.scenarioContainer.extraPadding {
  padding-bottom: 3.5rem;
}

.scenarioHeader {
  flex: 0 0 auto; /* bottom element fix */
}

.scenarioContent {
  flex: 1 0 auto; /* bottom element fix */
  overflow-x: auto;
}

.scenarioTab {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100% - 8rem);
  position: fixed;
  bottom: 0;
  transform: translateY(calc(100% - 3.5rem));
  transition: transform 500ms;
  cursor: pointer;
}

.scenarioTab.active {
  transform: none;
}

.scenarioTabBanner {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  height: 2.5rem;
  border-bottom: 0.5rem solid #46c3d7;
  position: relative;
}

.scenarioTabBannerBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  fill: #46c3d7;
}

.scenarioTabBannerBox {
  display: flex;
  align-items: flex-end;
  position: relative;
  left: 0.25rem;
}

.scenarioTabBannerTitle {
  margin: 0;
  color: #071920;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.25;
}

.scenarioTabBannerIcon {
  width: 1.75rem;
  height: 1.75rem;
  margin-left: 0.5rem;
  stroke: #071920;
  stroke-width: 10;
}

.scenarioTab.active .scenarioTabBannerIcon {
  transform: rotate(180deg);
}

.scenarioTabContent {
  flex: 0 0 auto;
  overflow-y: auto;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
