import React, { useMemo } from 'react';
import store from '@src/store/index.js';
import { arrayEqual } from '@src/utils/utils.js';
import * as Icon from '@src/components/Icon/Icon.jsx';
import { areGuessesValid } from './Numeric.jsx';

import './Numeric.css';

function renderEntryResult(value, unit) {
  const parts = [];
  parts.push(<strong key="0">{value[0]}</strong>);
  parts.push(' ' + unit[0]);
  if (unit[1]) {
    parts.push(' to ');
    parts.push(<strong key="1">{value[1]}</strong>);
    parts.push(' ' + unit[1])
  }
  return parts;
}

export default function NumericResult(props) {
  const { entries, answer } = props;
  const ownAnswer = store.getAnswer();
  const valid = useMemo(() => areGuessesValid(ownAnswer, entries), [ownAnswer, entries]);
  return (
    <div className="numericFrame">
      {entries.map((entry, i) => (
        <div key={'entry-' + i} className="numericEntry">
          <p className="numericEntryQuestion noMargin">{entry.question}</p>
          <table className="numericEntryTable">
            <tbody>
              {valid && (
                <tr>
                  <td>Your answer:</td>
                  <td>{renderEntryResult(ownAnswer[i], entry.unit)}</td>
                  <td>
                    {arrayEqual(ownAnswer[i], answer[i]) && (
                      <td>
                        <Icon.CheckInline className="numericResultEntryIcon" />
                      </td>
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td>Correct answer:</td>
                <td>{renderEntryResult(answer[i], entry.unit)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
