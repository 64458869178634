import React from 'react';

export function boldText(text) {
  let out = [];
  let cur = 0;
  const reg = /\*\*(.*?)\*\*/g;
  let match = reg.exec(text);
  let i = 0;
  while (match) {
    const index = match.index;
    if (cur < index) out.push(text.substring(cur, index));
    const str = match[1];
    out.push(<b key={i++}>{str}</b>);
    cur = index + str.length + 4;
    match = reg.exec(text);
  }
  if (cur < text.length) out.push(text.substring(cur));
  return out;
}
