// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.matchingResultItem {
  margin: 1.5rem 0;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.4;
}

.matchingResultItem p {
  margin: 0;
}

.matchingResultItemLabel {
  font-weight: 500;
}

.matchingResultItemAnswer {
  font-weight: 300;
}

.matchingResultItemIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.25rem;
  vertical-align: middle;
  fill: #72cf80;
}

.matchingResultItemResult {
  font-weight: 300;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
