import React from 'react';
import Markdown from 'react-markdown';
import classNames from 'classnames';
import store from '@src/store/index.js';
import Loader from '@src/components/Loader/Loader.jsx';
import Mcq from './Mcq/Mcq.jsx';
import McqResult from './Mcq/McqResult.jsx';
import Matching from './Matching/Matching.jsx';
import MatchingResult from './Matching/MatchingResult.jsx';
import Ordering from './Ordering/Ordering.jsx';
import OrderingResult from './Ordering/OrderingResult.jsx';
import Numeric from './Numeric/Numeric.jsx';
import NumericResult from './Numeric/NumericResult.jsx';
import Delegates from './Delegates/Delegates.jsx';

import './Activity.css';

const comps_ = {
  'mcq': [Mcq, McqResult],
  'matching-question': [Matching, MatchingResult],
  'ordering-question': [Ordering, OrderingResult],
  'numeric-entry': [Numeric, NumericResult]
};

function renderBody(props) {
  const comps = comps_[props.type];
  if (!comps) return null;

  const Comp = comps[props.result ? 1 : 0];
  if (!Comp) return null;

  return <Comp {...props} />;
}

function renderResultCta() {
  const lastActivityOfTask = store.isLastActivityOfTask();

  const message1 = lastActivityOfTask ? 'reveal the outcome' : 'continue the task';
  const message = `Please wait for the instructor to ${message1}.`;

  return <p className="activityCta">{message}</p>;
}

export default function Activity(props) {
  const {
    taskTitle,
    taskId,
    taskCount,
    activityId,
    activityCount
  } = store.roomState.use();
  return (
    <div className={classNames('activityContainer', props.className)}>
      <div className="activityFrame">
        <h2 className="activityTitle">{`Task ${taskId + 1} of ${taskCount}: ${taskTitle}.`}</h2>
        <h3 className="activitySubtitle">{`Question ${activityId + 1} of ${activityCount}`}</h3>
        <Markdown className="text activityMarkdown" skipHtml>{props.text}</Markdown>
        {renderBody(props)}
        {props.result ? renderResultCta() : <Delegates className="activityDelegates" />}
      </div>
    </div>
  );
}
