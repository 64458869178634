import React, { useMemo } from 'react';
import store from '@src/store/index.js';
import * as Icon from '@src/components/Icon/Icon.jsx';

import './OrderingResult.css';

function isValidAnswer(answer, items) {
  if (!answer) return false;
  const count = items.length;
  if (answer.length !== count) return false;
  const copy = answer.slice().sort((a, b) => a - b);
  for (let i = 0; i < count; ++i) {
    if (copy[i] !== i) return false;
  }
  return true;
}

export default function OrderingResult(props) {
  const { topLabel, bottomLabel, items, order, answer } = props;
  const ownAnswer = store.getAnswer();
  const valid = useMemo(() => isValidAnswer(ownAnswer, items), [ownAnswer, items]);
  let sectionName = 'Correct Order';
  if (topLabel && bottomLabel) {
    sectionName += ` (${topLabel} to ${bottomLabel})`;
  }
  sectionName += ':';
  return (
    <div className="orderingResultFrame">
      <div className="orderingResultSection">
        <h3 className="orderingResultSectionName">{sectionName}</h3>
        <ol className="orderingResultList">
          {answer.map((index, i) => (
            <li key={'item-' + i}>{items[index]}</li>
          ))}
        </ol>
      </div>
      {valid && (
        <div className="orderingResultSection">
          <h3 className="orderingResultSectionName">Your order:</h3>
          <ol className="orderingResultList">
            {ownAnswer.map((index, i) => (
              <li key={'item-' + i}>
                <span>{items[index]}</span>
                {ownAnswer[i] === answer[i] && (
                  <Icon.CheckInline className="matchingResultItemIcon" />
                )}
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
